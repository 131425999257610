<template>
    
    <div class="rows-loader" :class="{ disable: !hideVal }">
        
        <div class="rows-loader__row">
            <div class="rows-loader__field"></div>
            <div class="rows-loader__value"></div>
        </div>
        <div class="rows-loader__row">
            <div class="rows-loader__field"></div>
            <div class="rows-loader__value"></div>
        </div>
        <div class="rows-loader__row">
            <div class="rows-loader__field"></div>
            <div class="rows-loader__value"></div>
        </div>
        <div class="rows-loader__row">
            <div class="rows-loader__field"></div>
            <div class="rows-loader__value"></div>
        </div>
        <div class="rows-loader__row">
            <div class="rows-loader__field"></div>
            <div class="rows-loader__value"></div>
        </div>

    </div>

</template>

<script>

export default{
    props: ['hideVal'],
}
</script>

<style lang="scss" scoped>
@import "./loaders.scss";
</style>

