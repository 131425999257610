<template>

    <div class="btn-loader" :class="{ disable: !hideVal }"></div>
    
</template>

<script>

export default{
    props: ['hideVal'],
}
</script>

<style lang="scss" scoped>
@import "./loaders.scss";
</style>